import React from "react"
import { defaultSerializers } from "@sanity/block-content-to-react"
import { Link } from "gatsby"

export default {
  types: {
    block: props => {
      const { style = "normal" } = props.node

      if (style === "h1") {
        return <h1>{props.children}</h1>
      } else if (style === "h2") {
        return <h2>{props.children}</h2>
      } else if (style === "h3") {
        return <h3>{props.children}</h3>
      } else if (style === "small") {
        return <p className="small">{props.children}</p>
      }

      // Fall back to default handling
      return defaultSerializers.types.block(props)
    },
  },
  marks: {
    link: props => {
      if (props.mark.href[0] === "/") {
        return (
          <Link
            title={props.mark.title}
            target={props.mark.openInNewWindow ? "_blank" : null}
            rel={props.mark.openInNewWindow ? "noreferrer noopener" : null}
            className="link--underline"
            to={props.mark.href}
          >
            {props.children}
          </Link>
        )
      }
      return (
        <a
          title={props.mark.title}
          target={props.mark.openInNewWindow ? "_blank" : null}
          rel={props.mark.openInNewWindow ? "noreferrer noopener" : null}
          className="link--underline"
          href={props.mark.href}
        >
          {props.children}
        </a>
      )
    },
  },
}
